<script lang="ts" setup>
  import { computed, ref } from 'vue'

  //declare type RatingValue = 0 | 1 | 2 | 3 | 4 | 5
  interface IProps {
    ratingNumber?: number
    disabled?: boolean
    readonly?: boolean
  }
  const props = withDefaults(defineProps<IProps>(), {
    ratingNumber: 5,
    disabled: false,
    readonly: false,
  })
  const modelValue = defineModel<number>({ default: 1 })

  const hoverIdx = ref<any>(false)

  const hoverBind = computed(() => {
    return props.readonly || props.disabled ? 1 : 1.2
  })
</script>

<template>
  <div class="rating">
    <template v-for="idx in ratingNumber" :key="idx">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        aria-hidden="true"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        data-testid="StarIcon"
        class="rating-icon"
        :class="{ disabled, readonly }"
        fill="#faaf00"
        :style="`opacity: ${props.disabled ? '0.4' : ''}`"
        @click="props.readonly || props.disabled ? null : (modelValue = idx)"
        @mouseover="props.readonly || props.disabled ? null : (hoverIdx = idx)"
        @mouseleave="props.readonly || props.disabled ? null : (hoverIdx = false)"
      >
        <path
          v-if="hoverIdx ? idx <= hoverIdx : idx <= modelValue"
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        ></path>
        <path
          v-else
          d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
        ></path>
      </svg>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .rating {
    display: flex;
    &-icon {
      &:not(.readonly) {
        cursor: pointer;
      }
      &:hover {
        scale: v-bind(hoverBind);
      }
    }
  }
</style>
